import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import LoadingButton from '@mui/lab/LoadingButton'
import terms from 'common/terms'
import Loader from 'components/Loader'
import ModifyUserGroup from 'components/ModifyUserGroup/ModifyUserGroup'
import RequestSummary from 'components/RequestSummary/RequestSummary'
import SearchInput from 'components/SearchInput/SearchInput'
import { debounce } from 'lodash'
import {
  ReactElement, useEffect, useRef, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetRequests, setUserGroupSearch, UserState } from 'reducers/user'
import {
  AccessTypes,
  Group,
  UserGroupWithLevels, UserService,
} from 'services/cerbereTypes'
import { UserGroupLevels } from 'services/customTypes'
import GroupServices from 'services/GroupServices'
import UserServices from 'services/UserServices'
import { RootState, store } from 'Store'
import { EmptyObject } from 'types'
import { USER_GROUP_COLUMNS } from './columns'
import GroupList from './GroupList'
import './UserGroupManagement.scss'

type Props = {
  mode: string;
  userPermissions: UserService[];
}

const debouncedSearch = debounce(
  (val: string) => store.dispatch(setUserGroupSearch(val)),
  200,
)
// todo refactor move one component above
export default function UserGroupManagement({ mode, userPermissions }: Props): ReactElement {
  const dispatch = useDispatch()
  const {
    usersLoading, userGroupSearch, userInfo, userAssignLoading, showUserGroup, services,
    requestsSummary,
  } = useSelector((state: RootState) => state.users as UserState)
  const [groupsToRemove, setToRemove] = useState<string[]>([])
  const [groupsToAdd, setToAdd] = useState<string[]>([])
  const serviceWithGroups = useRef<EmptyObject<string>>({})
  const [groupModal, showGroupModal] = useState(false)
  const [levelsToModify, setLevelsToModify] = useState<string[]>([])
  const groupNames = useRef<{[id: string]: string}>({})

  const modifyGroups = (groups: string[]) => {
    if (mode === 'user') {
      setToRemove(groups)
    } else {
      setToAdd(groups)
    }
  }

  useEffect(() => {
    if (requestsSummary.length) {
      setToRemove([])
      setToAdd([])
    }
  }, [requestsSummary])

  useEffect(() => {
    if (showUserGroup) {
      setToRemove([])
      setToAdd([])
      dispatch(UserServices.getUser({ id: showUserGroup }))
    }

    return () => {
      dispatch(resetRequests())
      dispatch(setUserGroupSearch(null))
    }
  }, [])

  useEffect(() => {
    if (showUserGroup) {
      dispatch(UserServices.getUserGroups({ id: showUserGroup, search: userGroupSearch }))
    }
  }, [userGroupSearch])

  const handleChange = (value: string) => {
    if (!value) {
      dispatch(setUserGroupSearch(value))
      return
    }
    debouncedSearch(value)
  }

  const findChildren = (servs: UserService[], permissionsList: UserGroupLevels = {}) => {
    const tmpGroup: EmptyObject<string> = {}

    const iterate = (currentObj: UserGroupWithLevels, permList: UserGroupLevels, service: string) => {
      Object.entries(currentObj.has_access_types).forEach(([key, value]) => {
        if (!value) {
          permList[service][key as keyof AccessTypes] = value
        }
      })
      tmpGroup[service].push(currentObj.id)
      permList[currentObj.id] = { ...currentObj.has_access_types, isService: false }
      groupNames.current[currentObj.id] = `${service} - ${currentObj.displayName}`
      currentObj.children?.forEach(child => {
        iterate(child, permList, service)
      })
      return permList
    }

    servs.forEach(element => {
      tmpGroup[element.service] = []
      permissionsList[element.service] = {
        level1: true, level2: true, member: true, isService: true,
      }
      element.groups.forEach(grp => {
        iterate(grp, permissionsList, element.service)
      })
    })

    serviceWithGroups.current = tmpGroup
    return permissionsList
  }

  // visualized user groups permissions
  const userPermList = findChildren(userPermissions, {})

  const buildServicesList = () => {
    const servs = findChildren(services, {})

    const availableServicesList = () => {
      Object.entries(servs).forEach(([key, value]) => {
        if (!value.isService) {
          const cannotAssignToLevel2 = (value.level1
            && value.member
            && !value.level2) && !userPermList[key]?.level2

          const isDisabled = (Object.entries(value).every(([k, v]) => (k === 'disabled' || k === 'isService') || v)
          || cannotAssignToLevel2)
          servs[key] = { ...servs[key], disabled: isDisabled }
        }
      })

      Object.entries(servs).forEach(([key, value]) => {
        if (value.isService) {
          const groups = serviceWithGroups.current[key]

          servs[key] = {
            ...servs[key],
            disabled: groups.every(grp => servs[grp].disabled)
            || !services.find(serv => serv.service === key)?.groups.length,
          }
        }
      })
      return servs
    }

    return availableServicesList()
  }

  const servicesList = buildServicesList()

  const findMinimumRemoveLevel = (grps: string[]) => {
    const minLevel = ['member', 'level1', 'level2']
    if (grps.length) {
      const removeFromArray = (search: string) => {
        const index = minLevel.indexOf(search)
        if (index === -1) return
        minLevel.splice(index, 1)
      }

      grps.forEach(grp => {
        const group = servicesList[grp]

        if (!group.member) {
          removeFromArray('member')
        }

        if (!group.level1) {
          removeFromArray('level1')
        }

        if (!group.level2) {
          removeFromArray('level2')
        }
      })
    }
    return minLevel
  }

  const findMinmimumAddLevel = (grps: string[]) => ['member', 'level1', 'level2']
    .filter(elem => !findMinimumRemoveLevel(grps).includes(elem))

  const removeSelectedGroup = (group: Group) => {
    const groups = mode === 'user' ? groupsToRemove : groupsToAdd
    const updateGroup = mode === 'user' ? setToRemove : setToAdd
    const index = groups.indexOf(group.id)
    if (index !== -1) {
      const newGroups = [...groups]
      newGroups.splice(index, 1)

      if (!newGroups.length) {
        showGroupModal(false)
      }
      updateGroup(newGroups)
    }
  }

  const renderUserHeader = () => {
    const groupLength = Object.keys(servicesList).filter(key => !services.some(serv => serv.service === key)
    && Object.values(servicesList[key]).some(acc => acc)).length

    if ((!usersLoading)) {
      return (
        <div className="d-flex w-100 justify-content-between align-items-center">
          <div className="text">

            {groupLength === 0 && terms.User.noGroups}
            {groupLength === 1 && terms.User.memberOfOne}
            {groupLength > 1 && `
            ${terms.User.memberOf}
            ${groupLength}
            ${terms.User.followingGroups}
            :`}
          </div>
          {groupsToRemove.length > 0 && (
            <LoadingButton
              disableElevation
              disabled={userAssignLoading}
              loading={userAssignLoading}
              loadingPosition="start"
              startIcon={<RemoveIcon />}
              variant="contained"
              onClick={() => showGroupModal(true)}
              size="large"
              className="modify-groups"
            >
              {groupsToRemove.length === 1 ? terms.User.removeFromGroup
                : `${terms.User.remove}
              ${groupsToRemove.length}
              ${(terms.Group.groups).toLowerCase()}`}
            </LoadingButton>
          )}
        </div>
      )
    }
    return ''
  }

  const renderGroupHeader = () => (
    <div className="d-flex h-100 w-100 justify-content-between align-items-center">
      <div className="text">
        {`${terms.User.availableGroups} :`}
      </div>

      <div className="manage-buttons-container">
        <div className="search">
          <SearchInput value={userGroupSearch as string} onChange={handleChange} />
        </div>
        {groupsToAdd.length > 0 && (
          <LoadingButton
            disableElevation
            disabled={userAssignLoading}
            loading={userAssignLoading}
            loadingPosition="start"
            startIcon={<AddIcon />}
            variant="contained"
            onClick={() => showGroupModal(true)}
            className="modify-groups"
          >
            {groupsToAdd.length === 1 ? terms.Group.addToGroup
              : `${terms.Common.add}
              ${terms.Common.to}
              ${groupsToAdd.length}
              ${(terms.Group.groups).toLowerCase()}`}

          </LoadingButton>
        )}
      </div>
    </div>
  )

  const renderUserBody = () => {
    if ((usersLoading && userGroupSearch === null) || userAssignLoading) {
      return (
        <div
          className="loading"
        >
          <Loader />
        </div>
      )
    }

    return (
      <GroupList
        setGroups={modifyGroups}
        checked={groupsToRemove}
        services={services}
        permissions={servicesList}
        userPermissions={userPermList}
        groupList={serviceWithGroups.current}
        minCheckableLevel={findMinimumRemoveLevel(groupsToRemove)}
      />
    )
  }

  const renderGroupBody = () => {
    if (usersLoading || userAssignLoading) {
      return (
        <div
          className="loading"
        >
          <Loader />
        </div>
      )
    }
    return (
      <GroupList
        mode="group"
        setGroups={modifyGroups}
        permissions={servicesList}
        userPermissions={userPermList}
        services={services}
        groupList={serviceWithGroups.current}
        checked={groupsToAdd}
        minCheckableLevel={[]}
      />
    )
  }

  const removeUserFromGroups = () => {
    showGroupModal(false)
    levelsToModify.forEach(lvl => {
      if (lvl === 'member') {
        dispatch(UserServices.removeFromGroups({ groups: groupsToRemove, userId: userInfo.id as string }))
      }

      groupsToRemove.forEach(group => {
        if (lvl !== 'member') {
          dispatch(GroupServices.removeLevel({ level: lvl, groupId: group, userId: userInfo.id as string }))
        }
      })
    })
  }

  const addUserToGroups = () => {
    showGroupModal(false)
    levelsToModify.forEach(lvl => {
      if (lvl === 'member') {
        dispatch(UserServices.assignToGroups({ groups: groupsToAdd, userId: userInfo.id as string }))
      }

      groupsToAdd.forEach(group => {
        if (lvl !== 'member') {
          dispatch(GroupServices.assignToGroupWithLevel({
            level: lvl,
            groupId: group,
            user: { id: userInfo.id || '', display_name: userInfo.id || '' },
            userId: userInfo.id as string,
          }))
        }
      })
    })
  }

  useEffect(() => {
    if (groupModal) {
      setLevelsToModify([])
    }
  }, [groupModal])

  const selectLevels = (lvl: string) => {
    const index = levelsToModify.indexOf(lvl)
    if (index !== -1) {
      const newLvls = [...levelsToModify]
      newLvls.splice(index, 1)
      setLevelsToModify(newLvls)
    } else {
      setLevelsToModify(lvls => [...lvls, lvl])
    }
  }

  const formatSummary = () => requestsSummary.map(req => ({ ...req, username: groupNames.current[req.userId] }))

  return (
    <>
      <div className="manage-groups">
        {mode === 'user'
          ? renderUserHeader()
          : renderGroupHeader()}
      </div>
      <div className="list-groups">
        {mode === 'user' ? renderUserBody() : renderGroupBody()}
      </div>

      <ModifyUserGroup
        open={groupModal && !requestsSummary.length}
        toggle={() => showGroupModal(false)}
        groups={mode === 'user' ? groupsToRemove : groupsToAdd}
        groupsInfo={groupNames.current}
        user={userInfo}
        removeGroup={removeSelectedGroup}
        userPermissions={userPermList}
        availableLevels={mode === 'user' ? findMinimumRemoveLevel(groupsToRemove) : findMinmimumAddLevel(groupsToAdd)}
        confirmAction={mode === 'user' ? removeUserFromGroups : addUserToGroups}
        selectLevels={selectLevels}
        mode={mode}
      />

      <RequestSummary
        requests={formatSummary()}
        open={!!requestsSummary.length}
        toggle={() => {
          dispatch(UserServices.getUserGroups({ id: userInfo.id, search: userGroupSearch }))
          dispatch(resetRequests())
        }}
        columns={USER_GROUP_COLUMNS}
      />
    </>
  )
}
