import { Checkbox, FormControlLabel } from '@mui/material'
import terms from 'common/terms'
import CustomChip from 'components/CustomChip/CustomChip'
import ModalWrapper from 'components/ModalWrapper/ModalWrapper'
import RoleChip from 'components/RoleChip/RoleChip'
import { ReactElement } from 'react'
import { AccessTypes, Group, User } from 'services/cerbereTypes'
import { UserGroupLevels } from 'services/customTypes'
import './ModifyUserGroup.scss'
import { Button } from 'react-bootstrap'

type Props = {
  open: boolean;
  toggle: () => void;
  groups: string[];
  groupsInfo: {[id: string]: string};
  user: Partial<User>;
  availableLevels: string[];
  removeGroup: (grp: Group) => void;
  userPermissions: UserGroupLevels;
  confirmAction: () => void;
  selectLevels: (lvl : string) => void
  mode: string;
}

export default function ModifyUserGroup({
  open, toggle, groups, groupsInfo, user, removeGroup, userPermissions, availableLevels, confirmAction,
  selectLevels, mode,
}: Props): ReactElement {
  const isDisabled = (level: string) => {
    const lvl = level === 'member' ? 'level1' : 'level2'

    return !availableLevels.includes(level)
   || groups.some(grp => !userPermissions[grp]?.[lvl as keyof AccessTypes])
  }

  const title = mode === 'user' ? terms.User.removeLevels : terms.User.addLevels
  const buttonText = mode === 'user' ? terms.User.removeLevelFromGroups(groups.length)
    : terms.User.addLevelToGroups(`${user.firstName} ${user.lastName}`, groups.length)

  return (
    <ModalWrapper
      className="modify-user-group"
      title={title(`${user.firstName} ${user.lastName}`)}
      open={open}
      toggle={toggle}
      footerButton={false}
      footer={(
        <div>
          <Button
            className="button"
            variant="contained"
            onClick={confirmAction}
          >
            {buttonText}
          </Button>
        </div>
)}
    >
      <div className="chip-container">
        {groups.map(grp => (
          <CustomChip
            key={grp}
            object={{ id: grp, display_name: groupsInfo[grp] } as Group}
            onDelete={g => removeGroup(g as Group)}
          />
        ))}
      </div>
      <div className="label">{terms.User.selectLevels}</div>
      <div className="checkboxes">
        <FormControlLabel
          control={<Checkbox onChange={() => selectLevels('member')} disabled={isDisabled('member')} />}
          label={<RoleChip access={{ member: true, level1: false, level2: false }} />}
          labelPlacement="end"
        />
        <FormControlLabel
          control={<Checkbox onChange={() => selectLevels('level1')} disabled={isDisabled('level1')} />}
          label={<RoleChip access={{ member: false, level1: true, level2: false }} />}
          labelPlacement="end"
        />

        <FormControlLabel
          control={<Checkbox onChange={() => selectLevels('level2')} disabled={isDisabled('level2')} />}
          label={<RoleChip access={{ member: false, level1: false, level2: true }} />}
          labelPlacement="end"
        />
      </div>
    </ModalWrapper>
  )
}
