import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import SettingsIcon from '@mui/icons-material/Settings'
import { Badge, IconButton } from '@mui/material'
import { RootState, store } from 'Store'
import terms from 'common/terms'
import AuthorizationPopup from 'components/AuthorizationPopup/AuthorizationPopup'
import CustomButton from 'components/CustomButton/CustomButton'
import GroupInfo from 'components/GroupInfo/GroupInfo'
import PermissionsModal from 'components/PermissionsModal/PermissionsModal'
import RequestSummary from 'components/RequestSummary/RequestSummary'
import SearchInput from 'components/SearchInput/SearchInput'
import { debounce } from 'lodash'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  GroupState, resetUsers, setAddUsersMode, setMemberSearch, setSelectedGroup,
} from 'reducers/group'
import UserServices from 'services/UserServices'
import { Group, QueryStatus } from 'services/cerbereTypes'
import './DetailsBar.scss'
import GroupServices from 'services/GroupServices'
import { SUMMARY_COLUMNS } from 'components/RequestSummary/const'

const debouncedSearch = debounce(
  (val: string) => store.dispatch(setMemberSearch(val)),
  200,
)

type Props = {
  group: Group;
  length: number;
}

type AuthorizeMode = 'member' | 'admin' | 'none'
export default function DetailsBar({ group, length }: Props): ReactElement {
  const dispatch = useDispatch()
  const {
    memberSearch, selectedGroup, details, userAdded,
  } = useSelector((state: RootState) => state.groups as GroupState)
  const { userSearch } = useSelector((state: RootState) => state.users)
  const [showDetails, setShowDetails] = useState(false)
  const [showPerm, setShowPerm] = useState(false)
  const [showAuthorize, setShowAuthorize] = useState<AuthorizeMode>('none')

  useEffect(() => {
    dispatch(UserServices.getUsers({ search: userSearch, page: 1 }))
  }, [userSearch])

  useEffect(() => {
    if (userAdded.length && userAdded.every(user => user.status !== QueryStatus.pending)) {
      dispatch(GroupServices.getGroupDetails(selectedGroup.id))
    }
  }, [userAdded])

  const closeMenu = () => {
    dispatch(setSelectedGroup(undefined))
  }
  const handleChange = (value: string) => {
    if (!value) {
      dispatch(setMemberSearch(value))
      return
    }
    debouncedSearch(value)
  }

  const changeMode = (mode: boolean) => {
    dispatch(setAddUsersMode(mode))
  }

  useEffect(() => () => {
    changeMode(false)
  }, [selectedGroup])

  const toggleAuthorization = () => {
    setShowAuthorize(prev => (prev === 'none' ? 'member' : 'none'))
  }

  const toggleAdminAuthorization = () => {
    setShowDetails(false)
    setShowAuthorize(prev => (prev === 'none' ? 'admin' : 'none'))
  }

  const toggleSummary = () => {
    dispatch(resetUsers())
    toggleAuthorization()
  }

  return (
    <div className="details-bar">
      <div className="group-menu">
        <div className="details-group">
          {group.display_name && (
            <>
              <span className="name">
                {`${group.display_name}`}
              </span>
            </>
          )}
          <span className="count">
            {`(${length} ${+length <= 1 ? terms.Table.user : terms.Table.users})`}
          </span>

        </div>

        <div className="usergroup-close">
          <IconButton onClick={closeMenu}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>

      <div className="buttons-container">
        <SearchInput
          placeholder={terms.Group.searchUsers}
          value={memberSearch}
          onChange={newValue => handleChange(newValue)}
        />

        <CustomButton
          onClick={toggleAuthorization}
          value={terms.Group.addToGroup}
          startIcon={<AddIcon />}
        />

        <CustomButton
          value=""
          className="icon"
          onClick={() => setShowDetails(true)}
          startIcon={<Badge badgeContent={details.warning ? 1 : 0} overlap="rectangular"><SettingsIcon /></Badge>}
        />

      </div>

      <GroupInfo
        open={showDetails}
        toggle={() => setShowDetails(prev => !prev)}
        togglePermissions={() => setShowPerm(prev => !prev)}
        toggleAuthorization={toggleAdminAuthorization}
      />

      <PermissionsModal
        permissions={details.permissions}
        warning={details.warning}
        open={showPerm}
        slug={details.slug}
        title={(
          <>
            {terms.Group.groupPerm}
            <span className="group-title">
              {' '}
              {details.display_name}
            </span>
          </>
        )}
        toggle={() => setShowPerm(prev => !prev)}
      />

      <AuthorizationPopup
        open={showAuthorize !== 'none' && !userAdded.length}
        toggle={toggleAuthorization}
        title={showAuthorize === 'member' ? terms.Group.addUser : terms.Group.addAdmin}
      />

      <RequestSummary
        open={showAuthorize !== 'none' && !!userAdded.length}
        toggle={toggleSummary}
        requests={userAdded}
        columns={SUMMARY_COLUMNS}
      />

    </div>
  )
}
